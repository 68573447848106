import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Image from 'gatsby-image'

import SEO from '../components/SEO'
import FullWidthWrapper from '../components/FullWidthWrapper'
import ForegroundLayout from '../components/ForegroundLayout'
import BigHeading from '../components/BigHeading'
import FlexBox from '../components/FlexBox'
import AngledDivider from '../components/AngledDivider'
import ContactSection from '../components/ContactSection'
import Overlay from '../components/Overlay'

const Work = ({ data }) => (
  <React.Fragment>
    <SEO title={data.mdx.frontmatter.title} />
    <Hero
      title={data.mdx.frontmatter.title}
      image={data.mdx.fields.imageFile.childImageSharp.fluid}
    />
    <Content body={data.mdx.body} />
    <ContactSection />
  </React.Fragment>
)

const Hero = ({ title, image }) => (
  <FullWidthWrapper height="80vh">
    <Image
      fluid={image}
      alt=""
      css={`
        height: 100%;
      `}
    />
    <Overlay alpha={0.4} />
    <FlexBox
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      css={`
        position: absolute;
        top: 0;
        z-index: 1;
      `}
    >
      <BigHeading
        css={`
          text-align: center;
        `}
      >
        {title}
      </BigHeading>
    </FlexBox>
  </FullWidthWrapper>
)

const Content = ({ body }) => (
  <FullWidthWrapper
    minHeight="0"
    css={`
      background-color: #0a0a0a;
    `}
  >
    <AngledDivider color={'#0a0a0a'} position={'top'} />
    <ForegroundLayout
      css={`
        padding: 10vh 0;
      `}
    >
      <MDXRenderer>{body}</MDXRenderer>
    </ForegroundLayout>
    <AngledDivider color={'#0a0a0a'} position={'bottom'} />
  </FullWidthWrapper>
)

export const query = graphql`
  query MDXQuery($id: String!) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
      }
      body
      fields {
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default Work
